import Vue from 'vue'
import Vuex from 'vuex'
import request from './_api/request'
import customer from './modules/customer'
import account from './modules/account'
import feature from './modules/feature'
import logistic from './modules/logistic'
import commerce from './modules/commerce'

Vue.use(Vuex)

// const DashHostName = location.hostname === 'xti.co.id'

let toastTimeout = null
export default new Vuex.Store({
  state: {
    isClient: location.hostname === 'xti.co.id',
    user: null,
    storeData: [],
    storeActive: 0,
    areas: [
      { name: 'Surabaya - Indonesia', value: 1 },
      { name: 'Bali - Indonesia', value: 2 },
      { name: 'Madura - Indonesia', value: 3 },
      { name: 'Timika - Indonesia', value: 4 },
      { name: 'Jayapura - Indonesia', value: 5 },
      { name: 'Bandung - Indonesia', value: 6 },
      { name: 'Wonogiri - Indonesia', value: 7 }
    ],
    toast: {
      show: false,
      message: '',
      class: 'ld ld-slide-ttb-in'
    },
    map: {
      show: false,
      data: null,
      setPoint: {
        active: false,
        draggable: null,
        data: null
      }
    },
    payment: [
      // { id: 'cash', name: 'Cash' },
      // { id: 'card', name: 'EDC/Card' },
      // { id: 'qris', name: 'Qris' },
      { id: 'bank_transfer', name: 'Bank Transfer', customerRequired: true },
      { id: 'online', name: 'Xendit Payment', customerRequired: true }
    ],
    kbri_area: [],
    coordinators: [],
    dialogPrint: {
      show: false,
      runnerActive: false,
      data: null
    }
  },
  mutations: {
    DIALOG_PRINT_PROCESS (state, v) {
      state.dialogPrint.show = false
      state.dialogPrint.data = null
      if (v && v.show && v.data) {
        state.dialogPrint.show = v.show
        state.dialogPrint.data = v.data
      }
    },
    DIALOG_PRINT_RUNNER (state, v) {
      state.dialogPrint.runnerActive = v || false
    },
    MAP (state, v) {
      state.map.show = v.show || false
      state.map.data = v.data || null
      state.map.setPoint.active = v.setPoint ? v.setPoint.active : null
      state.map.setPoint.data = v.setPoint ? v.setPoint.data : null
      state.map.setPoint.draggable = v.setPoint ? v.setPoint.draggable || null : null
    },
    USER (state, v) {
      state.user = v || null
    },
    KBRI_AREA (state, v) {
      state.kbri_area = v || []
    },
    STORE_DATA (state, v) {
      state.storeData = v || []
    },
    COORDINATOR_DATA (state, v) {
      state.coordinators = v || []
    },
    STORE_ACTIVE (state, v) {
      state.storeActive = parseInt(v) || 0
    },
    TOAST (state, v) {
      state.toast.class = 'ld ld-slide-ttb-in'
      state.toast.show = v.show
      state.toast.message = v.message
    }
  },
  getters: {
    storeActive (state) {
      if (parseInt(state.storeActive)) {
        const f = state.storeData.find(r => parseInt(r.id) === parseInt(state.storeActive))
        return f || {}
      }
      return {}
    }
  },
  actions: {
    async GRAPH_SELLING (context, q) {
      return request.get(`graph/selling${(q || '')}`).then(res => res).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async GRAPH_STORE (context, q) {
      return request.get(`graph/store${(q || '')}`).then(res => res).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async DOWNLOAD_TTK (context, q) {
      return request.get(`download/ttk${(q || '')}`).then(res => res).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async COORDINATORS_GET (context) {
      return request.get('user/accounts?page=0&limit=1000&role=manager').then((res) => {
        context.commit('COORDINATOR_DATA', res.data.data.data || [])
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async AUTH_LOGIN (context, params) {
      return await request.post('dashboard', params)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async ME (context, query) {
      return request.get('user').then((res) => {
        if (res.status) {
          context.commit('USER', res.data.data)
          const role = res.data.data.role
          if (role === 'root' || role === 'admin' || role === 'manager') {
            context.commit('STORE_ACTIVE', 0)
          } else {
            context.commit('STORE_ACTIVE', parseInt(res.data.data.store))
            if (role === 'driver') {
              context.dispatch('trx/TRX_GET', '?store=' + parseInt(res.data.data.store) + '&driver=' + res.data.data.id + '&driverStatus=')
            }
          }
        } else {
          context.commit('USER', null)
        }
        return res
      }).catch((e) => {
        return null
      })
    },
    async WEBSITE_PROCESS (context, params) {
      return await request.put('/store/website', params).then(res => res).catch(err => err)
    },
    async WEBSITE_GET (context) {
      return await request.get('/store/website').then(res => res).catch(err => err)
    },
    async ME_UPDATE (context, params) {
      return await request.put('/user/me', params).then(res => res).catch(err => err)
    },
    async AREA_GET (context, q) {
      return await request.get('/shipping' + (q || '')).then(res => res).catch(err => err)
    },
    async KECAMATAN_GET (context, q) {
      return await request.get('/shipping/kecamatan' + (q || '')).then(res => res).catch(err => err)
    },
    async KBRI_AREA_MAP_GET (context, q) {
      return await request.get('/kbri/area?map=1').then(res => res).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async KBRI_AREA_GET (context, q) {
      return await request.get('/kbri/area').then((res) => {
        if (res.status) {
          context.commit('KBRI_AREA', res.data.data || [])
        } else {
          context.commit('KBRI_AREA', [])
        }
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async STORE (context, q) {
      return request.get('store' + (q || '')).then((res) => {
        if (res.status) {
          context.commit('STORE_DATA', res.data.data || [])
        } else {
          context.commit('STORE_DATA', [])
        }
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async STORE_PROCESS (context, params) {
      return request.post('store', params).then((res) => {
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async UPLOAD_FILE_BINARY (context, params) {
      return await request.post(`upload/binary${params.query || ''}`, params.data).then(res => res)
    },
    async UPLOAD_FILE (context, params) {
      params.source = process.env.NODE_ENV === 'production' ? (params.source || 'others') : 'localhost'
      return await request.post('upload', params).then(res => res)
    },
    async DOWNLOAD_ZIP (context, p) {
      return await request.get(`upload/download?pid=${p.project}`, { timeout: 9000000 }).then(res => res)
    },
    async DOWNLOAD_ZIP_SEPARATE (context, p) {
      return await request.post(`upload/download/${p.planid}?pid=${p.project}`).then(res => res)
    },
    TOAST (context, params) {
      if (toastTimeout) {
        clearInterval(toastTimeout)
      }
      context.commit('TOAST', params)
      if (params.show) {
        toastTimeout = setInterval(() => {
          setTimeout(() => {
            params.show = false
            params.message = ''
            context.commit('TOAST', params)
          }, 300)
          clearInterval(toastTimeout)
        }, 4000)
      }
    }
  },
  modules: {
    customer,
    account,
    feature,
    logistic,
    commerce
  }
})
