<template v-if="$store.state.user">
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height primary" dark outlined @click.prevent="$store.state.isClient ? DIALOG_SHOW() : $store.dispatch('TOAST', { show: true, message: 'Customer access only!' })">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Add Order
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-2">
        <div class="pl-md-4 pr-md-4 pt-4 pb-0">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class=""
            placeholder="Nama, email, telepon ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-3 d-flex align-center justify-center">
        <div class="px-sm-4 pt-4 pt-sm-0 pb-0 fill-width">
          <v-menu
            ref="menu"
            v-model="menu"
            :return-value.sync="dates"
            left
            offset-y
            :close-on-content-click="false"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Date Ranges"
                append-icon="mdi-calendar-outline"
                readonly
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
                @click:clear="dates = [], ORDER_GET(tableSearch)"
              />
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              light
              class="force-light-font"
              range
            >
              <!-- <v-spacer />
              <v-btn
                text
                class="text-capitalize"
                small
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                class="text-capitalize"
                small
                @click="$refs.menu.save(dates), (dates.length === 2 ? ORDER_GET() : '')"
              >
                Ok
              </v-btn> -->
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="8" class="col-sm-2">
        <div class="pr-4 pt-4 pb-4">
          <v-select
            v-model.number="tableSearchStatus"
            :items="StatusOrder"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class=""
            label="Order Status"
          />
        </div>
      </v-col>
      <v-col cols="4" class="col-sm-1">
        <div class="pt-4 pb-4 fill-width">
          <v-btn :disabled="tableTotal > 100" :loading="tableLoading && !tableLoadingId ? true : false" depressed color="primary" class="rounded-full fill-width" @click.prevent="DOWNLOAD_TTKS()">
            Print All
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="py-2">
              <div class="d-flex align-start flex-column text-uppercase">
                <span class="font-weight-bold caption">
                  #XTI{{ ('000000' + item.id).slice(-6) }}
                </span>
                <span v-if="item.shipping_ttk" class="d-flex align-center font-weight-bold caption">
                  <v-icon class="mr-1">
                    mdi-barcode
                  </v-icon>
                  {{ item.shipping_ttk }}
                </span>
              </div>
              <div class="d-flex align-center caption">
                <v-icon x-small class="mr-1">
                  mdi-calendar-clock
                </v-icon>
                {{ $localDT(item.created, 'datetimedefault') }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.shipping_name`]="{ item }">
            <div class="d-flex flex-column py-2">
              <div class="d-flex flex-column fill-width align-start justify-space-between text-capitalize">
                <div class="font-weight-bold">
                  {{ item.shipping_name }}
                </div>
                <div class="caption">
                  Client: {{ item.commerce_client_name }}
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.shipping_phone`]="{ item }">
            <div class="py-2">
              {{ item.shipping_phone }}
            </div>
          </template>
          <template v-slot:[`item.payment_subtotal`]="{ item }">
            <div class="py-2">
              Rp{{ parseInt(item.payment_subtotal).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.payment_shipping`]="{ item }">
            <div class="py-2">
              Rp{{ parseInt(item.payment_shipping).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.payment_total`]="{ item }">
            <div class="py-2">
              Rp{{ parseInt(item.payment_total).toLocaleString() }}
            </div>
          </template>
          <template #[`item.status`]="{ item }">
            <div class="py-2">
              {{ StatusOrderName(item.status, item.shipping_pickup_handling) }}
              <span v-if="parseInt(item.shipping_pickup_handling)" class="ml-1">
                ({{ item.shipping_pickup_handling_name || '-' }})
              </span>
              <div v-if="item.commerce_customer_password">
                Passcode: {{ item.commerce_customer_password }}
              </div>
              <div v-if="item.payment_detail">
                <div v-if="$StrToJson(item.payment_detail).receipt">
                  Payment Receipt :
                  <div>
                    <div class="text-uppercase">
                      {{ $StrToJson(item.payment_detail).name }}, {{ $StrToJson(item.payment_detail).account }} a/n {{ $StrToJson(item.payment_detail).account_number }}
                    </div>
                    <a :href="$StrToJson(item.payment_detail).receipt" target="_blank" class="d-flex align-center">
                      <v-icon small class="mr-1">
                        mdi-receipt
                      </v-icon>
                      <span class="caption">
                        Payment Receipt
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="!parseInt(item.shipping_ttk_id)" depressed small dark color="indigo" class="text-capitalize ma-1" @click.prevent="SET_COMMERCE_TTK(item)">
                <v-icon small class="mr-1">
                  mdi-truck-fast
                </v-icon>
                Set Ready To Ship
              </v-btn>
              <v-btn depressed small dark color="orange" class="text-capitalize ma-1" @click.prevent="DIALOG_SHOW(item)">
                <v-icon small class="mr-1">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
              <v-btn :href="`https://wa.me/62${item.shipping_phone}?text=Passcode: ${item.commerce_customer_password}, Invoice: ${'https://xti.co.id/' + item.invoice_hash}`" target="_blank" depressed small color="green" class="white--text text-capitalize  ma-1">
                <v-icon small class="mr-1">
                  mdi-whatsapp
                </v-icon>
                Share
              </v-btn>
              <v-btn :href="'https://xti.co.id/' + item.invoice_hash" target="_blank" depressed small color="green" class="white--text text-capitalize  ma-1">
                <v-icon small class="mr-1">
                  mdi-invoice-text
                </v-icon>
                Invoice
              </v-btn>
              <v-btn v-if="parseInt(item.commerce_logistic)" :loading="parseInt(tableLoadingId) === parseInt( item.id)" icon small dark color="secondary" class="text-capitalize ml-2" @click.prevent="DOWNLOAD_TTKS(item.commerce_logistic)">
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <div class="pt-2 pb-2 px-4 px-md-8 white fill-width">
              <v-divider class="mt-2 mb-3" />
              <div class="d-flex justify-end flex-column flex-sm-row">
                <div class="d-flex align-center font-weight-bold">
                  Total : {{ tableTotal }}
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialog.data">
        <v-card-title class="body-1 justify-center">
          - {{ dialog.data.id ? (dialog.edit ? 'Update Order' : 'Detail Order') : 'Tambah Order Baru' }} -
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: calc(100vh - 200px)">
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center pb-2 justify-space-between font-weight-bold">
                # Buyer
                <v-btn :disabled="!dialog.edit" text class="ml-2 font-weight-bold text-caption" color="blue" @click.prevent="CUSTOMER_SHOW()">
                  <v-icon class="mr-1 d-flex align-center" style="font-size:18px;line-height:140%;">
                    mdi-plus
                  </v-icon>
                  Buyer
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-autocomplete
                    v-model="dialog.data.commerce_customer"
                    :disabled="!dialog.edit"
                    :search-input.sync="customerQuery"
                    :name="Math.random()"
                    :items="Customer.data.map(r => { return { id: r.id, name: r.name, phone: r.phone, email: r.email, status: r.status } })"
                    item-text="name"
                    return-object
                    outlined
                    dense
                    hide-details
                    label="Select Buyer"
                    @change="dialogProduct.data ? (dialogProduct.data.commerce_client = parseInt(dialog.data.commerce_customer ? dialog.data.commerce_customer.commerce_client : 0)) : true"
                  />
                  <v-btn v-if="dialog.data.commerce_customer" :disabled="!dialog.edit" class="rounded ml-2" depressed @click.prevent="CUSTOMER_SHOW(dialog.data.commerce_customer)">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="">
                  <v-textarea
                    v-model="dialog.data.shipping_address"
                    :disabled="!dialog.edit"
                    outlined
                    rows="2"
                    dense
                    hide-details
                    label="Detail Address"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center justify-space-between pb-2">
                <strong>
                  # Product/Items
                </strong>
                <div v-if="productToggle" class="d-flex align-center">
                  <v-autocomplete
                    v-model="productSelected"
                    :disabled="!dialog.edit"
                    :items="Product.data"
                    item-text="name"
                    return-object
                    :name="Math.random()"
                    :search-input.sync="productQuery"
                    label="Search product ..."
                    outlined
                    autofocus
                    append-icon="mdi-magnify"
                    dense
                    clearable
                    class="rounded-pill"
                    hide-details
                  />
                  <v-btn icon class="ml-2 font-weight-bold text-caption" color="red" @click.prevent="productQuery = '', productToggle = !productToggle">
                    <v-icon class="d-flex align-center" style="font-size:18px;line-height:140%;">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div v-else class="d-flex align-center justify-end">
                  <v-btn :disabled="dialog.edit && dialog.data.commerce_customer ? false : true" text class="ml-2 font-weight-bold text-caption" color="blue" @click.prevent="PRODUCT_SHOW()">
                    Product
                    <v-icon class="ml-1 d-flex align-center" style="font-size:18px;line-height:140%;">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                  <v-btn :disabled="!dialog.edit" text class="ml-2 font-weight-bold text-caption" color="blue" @click.prevent="productToggle = !productToggle">
                    <v-icon class="mr-1 d-flex align-center" style="font-size:18px;line-height:140%;">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="px-4 py-2 rounded grey lighten-4">
                <div v-if="!dialog.data.items.length" class="text-center">
                  No Product
                </div>
                <div v-for="(i, iI) in dialog.data.items" :key="'product-item-' + iI" class="d-flex align-center justify-space-between text-body-2 my-1">
                  <div>
                    <div class="font-weight-bold text-body-1">
                      {{ iI + 1 }}. {{ i.commerce_product_name }}
                    </div>
                    <div>
                      <span :class="[(parseInt(i.commerce_product_price_discount_temp) ? 'text-decoration-line-through red--text text-caption' : 'font-weight-bold')]">
                        Rp{{ parseInt(i.commerce_product_price_temp || 0).toLocaleString() }}
                      </span>
                      <span v-if="parseInt(i.commerce_product_price_discount_temp)" class="ml-1 font-weight-bold">
                        Rp{{ parseInt(i.commerce_product_price_discount_temp || 0).toLocaleString() }}
                      </span>
                    </div>
                    <div class="caption">
                      Volume: P({{ parseInt(i.weight_length || 0) }}), L({{ parseInt(i.weight_width || 0) }}), T({{ parseInt(i.weight_height || 0) }}) = {{ $calculateWeightProduct(i).weight_volume }} gram(s) &sdot; Actual: {{ $calculateWeightProduct(i).weight_kg }} gram(s)
                    </div>
                    <div class="caption">
                      Chargeable: <strong>{{ parseFloat(parseFloat($calculateWeightProduct(i).chargeable/1000).toFixed(1)).toLocaleString() }} kg(s)</strong>
                    </div>
                  </div>
                  <div class="text-right">
                    <div class="d-flex align-center justify-end">
                      <v-icon :disabled="!dialog.edit" small @click.prevent="parseInt(dialog.data.items[iI].commerce_product_quantity) > 1 ? dialog.data.items[iI].commerce_product_quantity-- : 1">
                        mdi-minus
                      </v-icon>
                      <input v-model.number="dialog.data.items[iI].commerce_product_quantity" :disabled="!dialog.edit" type="text" style="width:40px;" class="text-center px-1 py-1 mx-2 rounded grey lighten-2">
                      <v-icon :disabled="!dialog.edit" small @click.prevent="dialog.data.items[iI].commerce_product_quantity++">
                        mdi-plus
                      </v-icon>
                    </div>
                    <div class="pt-2 font-weight-bold">
                      Rp{{ ((parseInt(i.commerce_product_price_discount_temp || 0) || parseInt(i.commerce_product_price_temp || 0)) * parseInt(i.commerce_product_quantity || 0)).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right pr-4 pt-4">
                Subtotal : Rp{{ orderSubtotal.toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" class="">
              <v-divider />
            </v-col>
            <v-col cols="12" class="">
              <div class="d-flex align-center pb-2 justify-space-between">
                <strong># Shipping</strong>
                <span>
                  Weight Total : {{ SumWeight(dialog.data.items || []).textKG }}
                </span>
              </div>
              <v-card outlined class="grey lighten-4 pa-4">
                <v-row>
                  <v-col cols="12" class="col-sm-6">
                    <v-autocomplete
                      v-model.number="dialog.data.shipping_from_kec"
                      :disabled="!dialog.edit"
                      :name="Math.random()"
                      :search-input.sync="areaqueryFrom"
                      :items="areasFromKecamatan"
                      item-value="kecamatan_id"
                      item-text="fullname"
                      outlined
                      dense
                      hide-details
                      label="Ship From (kecamatan name)"
                      autocomplete="disabled"
                      @change="PRICING_GET(), dialog.data.shipping = 0"
                    />
                  </v-col>
                  <v-col cols="12" class="col-sm-6">
                    <v-autocomplete
                      v-model.number="dialog.data.shipping_to_kec"
                      :disabled="!dialog.edit"
                      :name="Math.random()"
                      :search-input.sync="areaqueryTo"
                      :items="areasToKecamatan"
                      item-value="kecamatan_id"
                      item-text="fullname"
                      outlined
                      dense
                      hide-details
                      label="Ship To (kecamatan name)"
                      autocomplete="disabled"
                      @change="PRICING_GET(), dialog.data.shipping = 0"
                    />
                  </v-col>
                  <v-col cols="12" class="">
                    <v-autocomplete
                      v-model.number="dialog.data.shipping"
                      :disabled="!dialog.edit"
                      :name="Math.random()"
                      :items="shippingPricingData"
                      item-value="id"
                      item-text="price_detail"
                      outlined
                      clearable
                      dense
                      hide-details
                      label="Select Shipping"
                      autocomplete="disabled"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <div class="text-right pr-4 pt-4">
                Shipping Cost [{{ SumWeight(dialog.data.items || []).textKG }}] : +Rp{{ (orderShippingCost * (Math.ceil(SumWeight(dialog.data.items || []).total / 1000) || 1)).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" class="">
              <v-divider />
            </v-col>
            <v-col cols="12" class="text-right font-weight-bold pr-8 text-body-1">
              TOTAL : {{ (orderSubtotal + (orderShippingCost * (Math.ceil(SumWeight(dialog.data.items || []).total / 1000) || 1))).toLocaleString() }}
            </v-col>
            <v-col cols="12" class="">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center pb-2 justify-space-between font-weight-bold">
                # Payment
                <v-btn :disabled="!dialog.edit" text class="ml-2 font-weight-bold text-caption" color="blue" @click.prevent="CUSTOMER_BANK_SHOW()">
                  <v-icon class="mr-1 d-flex align-center" style="font-size:18px;line-height:140%;">
                    mdi-plus
                  </v-icon>
                  Payment
                </v-btn>
              </div>
              <div>
                <div v-if="!dialogCustomerBank.data.length" class="text-center pa-4">
                  no payment
                </div>
                <div v-for="(p, iP) in dialogCustomerBank.data" :key="'payment-' + iP" class="d-flex align-center justify-space-between py-2 px-3 rounded-lg grey lighten-4 mb-2">
                  <div>
                    <div class="text-caption text-uppercase grey--text">
                      {{ p.type }} {{ p.type_name }}
                    </div>
                    <div>
                      {{ p.bank_account_name }}
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    {{ p.bank_account_number }}
                    <span v-if="dialog.edit" class="pl-3" @click.prevent="CUSTOMER_BANK_DELETE(p.id)">
                      <v-icon small color="red" class="c-pointer">
                        mdi-delete
                      </v-icon>
                    </span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            text
            class="text-capitalize"
            @click="dialog.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="!dialog.edit"
            color="green darken-1"
            depressed
            dark
            class="text-capitalize px-4 font-weight-bold"
            @click="ORDER_PROCESS()"
          >
            {{ dialog.data.id ? (dialog.edit ? 'Update Order' : 'Detail Order') : 'Place Order' }}
          </v-btn>
        </v-card-actions>
        <v-dialog
          v-model="dialogCustomer.show"
          persistent
          scrollable
          max-width="450"
        >
          <v-card v-if="dialogCustomer.data" class="px-4">
            <v-card-title class="body-1 justify-center text-uppercase font-weight-bold">
              {{ parseInt(dialogCustomer.data.id) ? 'UPDATE' : 'TAMBAH' }} CUSTOMER
            </v-card-title>
            <v-divider />
            <v-card-text class="py-4 px-0" style="max-height: 90vh;overflow:hidden;">
              <v-text-field
                v-model="dialogCustomer.data.name"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Customer Name"
              />
              <v-text-field
                v-model="dialogCustomer.data.email"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Email"
              />
              <v-text-field
                v-model.number="dialogCustomer.data.phone"
                outlined
                dense
                hide-details
                prefix="+62"
                class="mb-4"
                label="Phone Number"
              />
              <v-select
                v-model.number="dialogCustomer.data.status"
                :items="[{ id: -1, name: '- Select Customer -' }, { id: 1, name: 'Active' }, { id: 0, name: 'Not Active' }]"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                dense
                class="d-block mb-4"
                label="Customer Status"
              />
              <v-text-field
                v-model="dialogCustomer.data.password"
                type="text"
                outlined
                dense
                hide-details
                class="mb-4"
                :label="dialogCustomer.data.password ? 'Change Passcode' : 'Update Passcode'"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-0">
              <v-btn
                color="red darken-1"
                text
                @click="dialogCustomer.show = false"
              >
                Close
              </v-btn>
              <v-spacer />
              <v-btn
                :disabled="!dialog.edit"
                color="primary"
                dark
                depressed
                @click="CUSTOMER_PROCESS()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogCustomerBank.show"
          persistent
          scrollable
          max-width="450"
        >
          <v-card v-if="dialogCustomerBank.detail" class="px-4">
            <v-card-title class="body-1 justify-center text-uppercase font-weight-bold">
              {{ parseInt(dialogCustomerBank.detail.id) ? 'UPDATE' : 'TAMBAH' }} PEMBAYARAN
            </v-card-title>
            <v-divider />
            <v-card-text class="py-4 px-0" style="max-height: 90vh;overflow:hidden;">
              <v-select
                v-model="dialogCustomerBank.detail.type"
                :items="['BANK', 'E-WALLET']"
                outlined
                hide-details
                dense
                class="d-block mb-4"
                label="Payment Type"
              />
              <v-text-field
                v-model="dialogCustomerBank.detail.type_name"
                :items="dialogCustomerBank.detail.type === 'BANK'
                  ? ['BCA', 'MANDIRI', 'BRI', 'BNI', 'BANK JAGO']
                  : ['GOPAY', 'DANA', 'OVO']"
                outlined
                hide-details
                dense
                class="d-block mb-4"
                label="Payment/Bank Name"
              />
              <v-text-field
                v-model="dialogCustomerBank.detail.bank_account_number"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Account Number"
                @keypress="$NumOnly"
              />
              <v-text-field
                v-model="dialogCustomerBank.detail.bank_account_name"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Account Name"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-0">
              <v-btn
                color="red darken-1"
                text
                @click="dialogCustomerBank.show = false"
              >
                Close
              </v-btn>
              <v-spacer />
              <v-btn
                :disabled="!dialog.edit"
                color="primary"
                dark
                depressed
                @click="CUSTOMER_BANK_PROCESS()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProduct.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialogProduct.data" >
        <v-card-title class="body-1 justify-center">
          - {{ dialogProduct.data.id ? 'Update Produk' : 'Tambah Produk Baru' }} -
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: calc(100vh - 200px)">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="dialogProduct.data.name"
                outlined
                rows="1"
                dense
                hide-details
                label="Product Name"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="dialogProduct.data.description"
                outlined
                rows="2"
                dense
                hide-details
                label="Product Description"
              />
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="col-sm-6 d-flex align-center">
                  <v-text-field
                    v-model.number="dialogProduct.data.price"
                    outlined
                    dense
                    hide-details
                    prefix="Rp"
                    label="Default Price"
                  />
                </v-col>
                <v-col cols="12" class="col-sm-6 d-flex align-center">
                  <v-text-field
                    v-model.number="dialogProduct.data.price_discount"
                    outlined
                    dense
                    hide-details
                    prefix="Rp"
                    label="Price Discount"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="col-sm-6 d-flex align-start justify-center flex-column">
                  <div class="d-flex align-center pb-1">
                    Volume : {{ parseFloat(dialogCalculate.weight_volume).toLocaleString() }} gram(s)
                  </div>
                  <div class="d-flex align-center">
                    <div class="d-flex align-center px-2 py-1 grey lighten-3 mr-1 rounded-md">
                      P
                      <input v-model.number="dialogProduct.data.weight_length" class="ml-2 mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 40px;">
                      cm
                    </div>
                    <div class="d-flex align-center px-2 py-1 grey lighten-3 mr-1 rounded-md">
                      L
                      <input v-model.number="dialogProduct.data.weight_width" class="ml-2 mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 40px;">
                      cm
                    </div>
                    <div class="d-flex align-center px-2 py-1 grey lighten-3 mr-1 rounded-md">
                      T
                      <input v-model.number="dialogProduct.data.weight_height" class="ml-2 mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 40px;">
                      cm
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="col-sm-3 d-flex align-start justify-center flex-column">
                  <div class="d-flex align-center fill-width pb-1">
                    Weight
                  </div>
                  <div class="d-flex align-start fill-width flex-column">
                    <div class="d-flex align-start">
                      <input v-model.number="dialogProduct.data.weight_kg" class="mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 60px;"> gram(s)
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="col-sm-3 d-flex align-start justify-center flex-column">
                  <div class="d-flex align-center fill-width pb-1">
                    Chargeable Weight
                  </div>
                  <div class="d-flex align-start fill-width flex-column">
                    <div class="d-flex align-start font-weight-bold">
                      {{ parseFloat(dialogCalculate.chargeable).toLocaleString() }} gram(s)
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            text
            class="text-capitalize"
            @click="dialogProduct.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <!-- :disabled="!$store.state.isClient" -->
          <v-btn
            color="green darken-1"
            depressed
            dark
            class="text-capitalize px-4 font-weight-bold"
            @click="PRODUCT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
let searchInterval = null
let searchAreaFromTimeout = null
let searchAreaToTimeout = null
let searchProductTimeout = null
let searchCustomerTimeout = null

export default {
  name: 'Customer',
  data: () => ({
    areaqueryFrom: '',
    areasFromKecamatan: [],
    areaqueryTo: '',
    areasToKecamatan: [],
    menu: false,
    dates: [],
    headers: [
      {
        text: 'ORDER ID',
        value: 'id'
      },
      { text: 'Name', value: 'shipping_name' },
      { text: 'No Telepon', value: 'shipping_phone' },
      { text: 'Amount Subtotal', value: 'payment_subtotal' },
      { text: 'Amount Shipping', value: 'payment_shipping' },
      { text: 'Amount Total', value: 'payment_total' },
      { text: 'Status', value: 'status' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableLoadingId: null,
    table: [],
    tableTotal: 0,
    tableIds: null,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: -1,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 25,
      page: 1
    },
    dialog: {
      show: false,
      data: null
    },
    loading: false,
    Product: {
      data: [],
      total: 0
    },
    productToggle: false,
    productQuery: '',
    productSelected: null,
    Customer: {
      data: [],
      total: 0
    },
    customerQuery: '',
    customerSelected: null,
    dialogCustomer: {
      show: false,
      edit: false,
      data: null
    },
    dialogCustomerBank: {
      show: false,
      detail: null,
      data: []
    },
    dialogProduct: {
      show: false,
      data: null
    },
    shippingPricingData: [],
    StatusOrder: [
      { name: 'Tampilkan Semua', value: -1 },
      { name: 'New Order', value: 0 },
      { name: 'New Order (Paid)', value: 1000 },
      { name: 'Ready To Ship', value: 1 },
      { name: 'Shipping', value: 2 },
      { name: 'Completed', value: 3 },
      { name: 'Canceled', value: 99 }
    ]
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    orderSubtotal () {
      if (this.dialog.data && this.dialog.data.items && this.dialog.data.items.length) {
        const items = this.dialog.data.items || []
        return items.reduce(function (a, b) {
          return a + ((parseInt(b.commerce_product_price_discount_temp) || parseInt(b.commerce_product_price_temp)) * parseInt(b.commerce_product_quantity))
        }, 0)
      }
      return 0
    },
    orderShippingCost () {
      if (this.dialog.data && parseInt(this.dialog.data.shipping) && this.shippingPricingData.length) {
        const costSelected = this.shippingPricingData.find(r => parseInt(r.id) === parseInt(this.dialog.data.shipping))
        if (costSelected) {
          return parseInt(costSelected.price) || 0
        }
      }
      return 0
    },
    dialogCalculate () {
      if (this.dialogProduct.show && this.dialogProduct.data) {
        const d = Object.assign({}, this.dialogProduct.data || {})
        const wkg = parseFloat(d.weight_kg) || 0
        const vlength = parseFloat(d.weight_length) || 0
        const vwidth = parseFloat(d.weight_width) || 0
        const vheight = parseFloat(d.weight_height) || 0
        const tVolume = vlength * vwidth * vheight
        const tVolumeWeight = tVolume > 0 ? (tVolume / 5000) : 0
        return {
          weight_kg: wkg,
          weight_volume: tVolumeWeight * 1000,
          chargeable: Math.max(wkg, tVolumeWeight * 1000)
        }
      }
      return {
        weight_kg: 0,
        weight_volume: 0,
        chargeable: 0
      }
    }
  },
  watch: {
    dates: function () {
      if (!this.dates.length || this.dates.length === 2) {
        this.$refs.menu.save(this.dates)
        this.ORDER_GET(this.tableSearch)
        this.menu = false
      }
    },
    productSelected (v) {
      if (v) {
        const isExist = this.dialog.data.items.find(r => r.commerce_product === parseInt(v.id))
        if (isExist) {
          this.$store.dispatch('TOAST', { show: true, message: 'Product already selected!' })
          return
        }
        const item = {
          commerce_client: 0,
          commerce_customer: 0,
          commerce_order: parseInt(this.dialog.data.id) || 0,
          commerce_product: parseInt(v.id),
          commerce_product_quantity: 1,
          commerce_product_capture: null,
          commerce_product_name: v.name,
          commerce_product_price: 0,
          commerce_product_price_discount: 0,
          commerce_product_price_temp: parseInt(v.price) || 0,
          commerce_product_price_discount_temp: parseInt(v.price_discount) || 0,
          weight_kg: parseFloat(v.weight_kg) || 0,
          weight_length: parseFloat(v.weight_length) || 0,
          weight_width: parseFloat(v.weight_width) || 0,
          weight_height: parseFloat(v.weight_height) || 0,
          status: 1
        }
        this.dialog.data.items.push(item)
      }
    },
    productQuery (v) {
      if (searchProductTimeout) {
        clearTimeout(searchProductTimeout)
      }
      searchProductTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    },
    customerQuery (v) {
      if (searchCustomerTimeout) {
        clearTimeout(searchCustomerTimeout)
      }
      searchCustomerTimeout = setTimeout(() => {
        this.CUSTOMER_GET()
      }, 300)
    },
    areaqueryFrom (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areasFromKecamatan.find(r => r.fullname === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaFromTimeout) {
        clearTimeout(searchAreaFromTimeout)
      }
      searchAreaFromTimeout = setTimeout(() => {
        this.areaGet('from', '?q=' + v)
        clearTimeout(searchAreaFromTimeout)
      }, 300)
    },
    areaqueryTo (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areasToKecamatan.find(r => r.fullname === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaToTimeout) {
        clearTimeout(searchAreaToTimeout)
      }
      searchAreaToTimeout = setTimeout(() => {
        this.areaGet('to', '?q=' + v)
        clearTimeout(searchAreaToTimeout)
      }, 300)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ORDER_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ORDER_GET(this.tableSearch)
    },
    tableSearchCoordinator () {
      this.ORDER_GET(this.tableSearch)
    },
    options () {
      this.ORDER_GET(this.tableSearch)
    }
  },
  created () {
    // if (this.$store.state.user) {
    //   if (!this.$role(['manager', 'svp'], this.$store.state.user.role)) {
    //     this.$router.replace('/')
    //   }
    // } else {
    //   this.$router.replace('/')
    // }
  },
  methods: {
    SumWeight (d) {
      d = d || []
      let total = 0
      for (let a = 0; a < d.length; a++) {
        const i = d[a]
        const sutotal = parseFloat(this.$calculateWeightProduct(i).chargeable)
        const qty = parseInt(i.commerce_product_quantity) || 1
        total += (sutotal * qty).toFixed(1)
      }
      return {
        total: total,
        text: total.toLocaleString() + 'gram(s)',
        textKG: parseFloat((total / 1000).toFixed(1)).toLocaleString() + 'kg(s)'
      }
    },
    SET_COMMERCE_TTK (payload) {
      if (!confirm('Set as Ready To Ship? auto generate ttk number!')) {
        return false
      }
      this.$store.dispatch('commerce/SET_COMMERCE_TTK', payload)
        .then((res) => {
          if (res.status) {
            this.ORDER_GET(this.tableSearch)
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil digenerate' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    StatusOrderName (st, stHandling) {
      if (parseInt(st) === 1 && parseInt(stHandling)) {
        return 'Picked up'
      }
      return ((parseInt(st) >= 0 && parseInt(st) <= 99)
        ? ((this.StatusOrder.find(r => parseInt(r.value) === parseInt(st)) || {}).name || '-')
        : '-')
    },
    DOWNLOAD_TTKS (id) {
      let ttks = ''
      if (parseInt(id)) {
        ttks = id
      } else if (!this.tableIds) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada data!' })
        return false
      } else {
        ttks = (this.tableIds || '').split(' ').join('')
      }
      const fullQuery = `?page=1&limit=1000&sortby=created&sort=DESC&store=-1&ttks=[${ttks}]`
      this.tableLoadingId = parseInt(id) || null
      this.tableLoading = true
      this.$store.dispatch('logistic/SHIPPING_GET', fullQuery)
        .then((res) => {
          if (res && res.status && res.data.data && res.data.data.filename) {
            const a = document.createElement('a')
            a.setAttribute('href', res.data.data.filename)
            a.setAttribute('target', '_blank')
            a.click()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
          this.tableLoading = false
          this.tableLoadingId = null
          // this.loadingDownload = false
        })
    },
    async PRICING_GET () {
      const params = {
        vendor: 0,
        q: '?q='
      }
      if (this.dialog.show && this.dialog.data) {
        const dataOrigin = parseInt(this.dialog.data.shipping_from_kec)
        const dataDestination = parseInt(this.dialog.data.shipping_to_kec)
        params.q += `&kecamatan_origin=${(dataOrigin || 0)}`
        params.q += `&kecamatan_destination=${(dataDestination || 0)}`
        if (!dataOrigin || !dataDestination) {
          return false
        }
        await this.$store.dispatch('logistic/SHIPPING_PRICING_GET', params)
          .then((res) => {
            this.shippingPricingData = res.data.data || []
          })
      }
    },
    CUSTOMER_SHOW (d) {
      if (d) {
        d.password = null
        this.dialogCustomer.data = Object.assign({}, d)
      } else {
        this.dialogCustomer.data = {
          commerce_client: 0,
          name: null,
          email: null,
          phone: null,
          password: null,
          payment_list: null,
          status: 1
        }
      }
      this.dialogCustomer.show = true
    },
    CUSTOMER_GET () {
      this.$store.dispatch('commerce/CUSTOMER_SEARCH', `?q=${(this.customerQuery || '')}&status=1`)
        .then((res) => {
          if (res.status) {
            this.Customer.data = res.data.data.data
            this.Customer.total = parseInt(res.data.data.total) || 0
          } else {
            this.Customer.data = []
            this.Customer.total = 0
          }
        })
    },
    CUSTOMER_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please wait!' })
        return false
      }
      const processedData = Object.assign({}, this.dialogCustomer.data)
      if (!processedData.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama customer masih kosong!' })
        return false
      }

      if (!processedData.email) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email masih kosong!' })
        return false
      } else if (!this.$validateEmail(processedData.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email tidak valid!' })
        return false
      }

      if (!parseInt(processedData.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No telepon masih kosong!' })
        return false
      } else if (!this.$validatePhone(processedData.phone + '')) {
        this.$store.dispatch('TOAST', { show: true, message: 'No telepon tidak valid!' })
        return false
      }

      if (!processedData.password) {
        delete processedData.password
      }

      this.loading = true
      this.$store.dispatch('commerce/CUSTOMER_CREATE', processedData)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (res.data.status) {
              this.customerQuery = res.data.data.name
              this.dialog.data.commerce_customer = { id: res.data.data.id, name: res.data.data.name, phone: res.data.data.phone, email: res.data.data.email, status: res.data.data.status }
              this.dialogCustomer.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedData.id ? 'Berhasil diupdate' : 'Berhasil ditambah' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    CUSTOMER_BANK_SHOW (d) {
      if (d) {
        this.dialogCustomerBank.detail = Object.assign({}, d)
      } else {
        this.dialogCustomerBank.detail = {
          type: 'BANK',
          type_name: 'BCA', // bca, mandiri, bri, gopay, ovo, dana, etc
          bank_account_number: null,
          bank_account_name: null,
          notes: null,
          status: 1
        }
      }
      this.dialogCustomerBank.show = true
    },
    CUSTOMER_BANK_GET () {
      this.dialogCustomerBank.data = []
      this.$store.dispatch('customer/CUSTOMER_BANK_GET')
        .then((res) => {
          if (res?.status) {
            this.dialogCustomerBank.data = res.data.data || []
          }
        })
    },
    CUSTOMER_BANK_PROCESS () {
      const processedData = Object.assign({}, this.dialogCustomerBank.detail)
      if (!processedData.bank_account_number) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nomor akun bank/pembayaran masih kosong!' })
        return false
      }
      if (!processedData.bank_account_name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama akun bank/pembayaran masih kosong!' })
        return false
      }

      this.$store.dispatch('customer/CUSTOMER_BANK_PROCESS', processedData)
        .then((res) => {
          this.dialogCustomerBank.show = false
          this.CUSTOMER_BANK_GET()
        })
    },
    CUSTOMER_BANK_DELETE (cbid) {
      this.$store.dispatch('customer/CUSTOMER_BANK_DELETE', cbid)
        .then((res) => {
          this.CUSTOMER_BANK_GET()
        })
    },
    PRODUCT_SHOW (data) {
      if (data) {
        this.dialogProduct.data = Object.assign({}, data)
      } else {
        this.dialogProduct.data = {
          commerce_client: parseInt(this.dialog.data?.commerce_customer?.commerce_client) || 0, // product owner
          name: null,
          description: null,
          images: null,
          price: 0,
          price_discount: 0,
          weight_kg: 0,
          weight_length: 0,
          weight_width: 0,
          weight_height: 0,
          status: 1
        }
      }
      this.dialogProduct.show = true
    },
    PRODUCT_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please wait!' })
        return false
      }
      const processedData = Object.assign({}, this.dialogProduct.data)
      if (!processedData.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama produk masih kosong!' })
        return false
      }

      if (!processedData.description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Keterangan produk masih kosong!' })
        return false
      }

      this.loading = true
      this.$store.dispatch('commerce/PRODUCT_CREATE', processedData)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (res.data.data.status) {
              this.PRODUCT_GET()
              this.dialogProduct.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedData.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    PRODUCT_GET () {
      this.$store.dispatch('commerce/PRODUCT_SEARCH', `?q=${(this.productQuery || '')}&status=1`)
        .then((res) => {
          if (res.status) {
            this.Product.data = res.data.data.data
            this.Product.total = parseInt(res.data.data.total) || 0
          } else {
            this.Product.data = []
            this.Product.total = 0
          }
        })
    },
    async DIALOG_SHOW (data) {
      this.productQuery = ''
      this.productSelected = null
      // this.dialog.edit = true
      this.dialog.edit = this.$store.state.isClient && (data && data.id ? !parseInt(data.status) : true)
      if (data) {
        const d = Object.assign({}, data)
        if (d.shipping_name) {
          this.customerQuery = d.shipping_name
          d.commerce_customer = {
            id: d.commerce_customer,
            name: d.commerce_customer_name || '-',
            name_client: d.commerce_client_name || '-',
            phone: d.commerce_customer_phone,
            email: d.commerce_customer_email,
            status: d.commerce_customer_status
          }
        }
        if (d.shipping_from_fullname && d.shipping_to_fullname) {
          this.areaqueryFrom = d.shipping_from_fullname || ''
          this.areaqueryTo = d.shipping_to_fullname || ''
        }
        if (!d.items) {
          d.items = []
        }
        const items = await this.$store.dispatch('commerce/ORDER_ITEM_SEARCH', d.id)
        if (items && items.status) {
          d.items = items.data.data.items || []
        }
        this.dialog.data = d
      } else {
        this.dialog.data = {
          commerce_client: 0,
          commerce_customer: null,
          commerce_logistic: 0,
          payment_subtotal: 0,
          payment_shipping: 0,
          payment_total: 0,
          payment_timestamp: null,
          payment_detail: null,
          shipping: 0,
          shipping_from: 0,
          shipping_to: 0,
          shipping_from_kec: 0,
          shipping_to_kec: 0,
          shipping_name: null,
          shipping_phone: null,
          shipping_address: null,
          status: 0,
          items: []
        }
      }
      this.dialog.show = true
      this.PRICING_GET()
      this.CUSTOMER_BANK_GET()
    },
    areaGet (act, q, id) {
      this.$store.dispatch('KECAMATAN_GET', q).then((res) => {
        if (res.status) {
          if (act === 'from') {
            this.areasFromKecamatan = res.data.data || []
          } else {
            this.areasToKecamatan = res.data.data || []
          }
        }
      })
    },
    ORDER_GET (q) {
      this.tableIds = null
      let status = this.tableSearchStatus > -1 ? this.tableSearchStatus : ''
      let statusPaid = ''
      this.tableLoading = true
      if (status === 1000) {
        status = 0
        statusPaid = '&statuspaid=1'
      }
      const fromTo = this.GetDates()
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('commerce/ORDER_SEARCH', query + (q ? ('&q=' + q) : '') + fromTo + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '') + statusPaid)
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
            this.tableIds = res.data.data.ids || null
          } else {
            this.table = []
            this.tableTotal = 0
            this.tableIds = null
          }
          this.tableLoading = false
        })
    },
    ORDER_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please wait!' })
        return false
      }
      const processedData = Object.assign({}, this.dialog.data)
      if (!processedData.commerce_customer) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select buyer!' })
        return false
      } else if (!parseInt(processedData.commerce_customer.id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid buyer!' })
        return false
      }
      const kecFrom = this.areasFromKecamatan.find(r => parseInt(r.kecamatan_id) === parseInt(processedData.shipping_from_kec))
      const kecTo = this.areasToKecamatan.find(r => parseInt(r.kecamatan_id) === parseInt(processedData.shipping_to_kec))
      if (kecFrom) {
        processedData.shipping_from = kecFrom.city_id
      }
      if (kecTo) {
        processedData.shipping_to = kecTo.city_id
      }

      processedData.shipping_name = processedData.commerce_customer.name
      processedData.shipping_phone = processedData.commerce_customer.phone
      processedData.commerce_customer = parseInt(processedData.commerce_customer.id)
      processedData.payment_subtotal = this.orderSubtotal
      processedData.payment_shipping = (this.orderShippingCost * (Math.ceil(this.SumWeight(this.dialog.data.items || []).total / 1000) || 1))
      processedData.payment_total = this.orderSubtotal + processedData.payment_shipping

      this.loading = true
      this.$store.dispatch('commerce/ORDER_CREATE', processedData)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (!res.data.data.message || res.data.data.message === 'success') {
              this.ORDER_GET(this.tableSearch)
              this.dialog.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedData.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>
