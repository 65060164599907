<template>
  <v-app :class="[$route.params.invoice ? 'print-force' : '']">
    <Toast />
    <Auth v-if="!$route.params.invoice && !$store.state.user" />
    <v-main v-else-if="$route.params.invoice" class="grey lighten-4 text-center grey lighten-3">
      <div v-if="!invoiceLoading && invoice" class="ma-auto grey lighten-4" style="max-width:1000px;min-height:100dvh;">
        <div class="pa-8 title font-weight-bold d-flex align-center justify-space-between">
          <img
            src="/xie-trans.png"
            width="85"
            max-width="85"
            class="d-block"
          />
          <div>
            ORDER #{{ (invoice.id + '').padStart(6, '0') }}
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="black" style="height:8px;width:75%;" />
          <div class="primary" style="height:8px;width:25%;" />
        </div>
        <div class="pa-8">
          <div class="text-left d-flex align-center justify-space-between">
            <strong>
              To : {{ invoice.commerce_client_name }} <em class="font-weight-regular">({{ invoice.commerce_client_email }})</em>
            </strong>
            <span class="grey--text text-body-2">
              {{ $localDT(invoice.created, 'daydatetime') }}
            </span>
          </div>
          <div class="pt-4 text-body-2">
            <v-divider class="fill-width mt-4 mb-2" />
            <table class="fill-width">
              <thead class="grey lighten-3">
                <tr class="grey--text font-weight-bold">
                  <td class="pa-2">No</td>
                  <td class="pa-2">Items</td>
                  <td class="pa-2">Price</td>
                  <td class="pa-2">Quantity</td>
                  <td class="pa-2">Amount</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="5">
                    <v-divider class="mt-2" />
                  </td>
                </tr>
                <tr v-for="(i, ii) in invoice.items" :key="'invoice-item-' + ii">
                  <td class="px-2 py-1 text-center">{{ ii + 1 }}</td>
                  <td class="px-2 py-1 text-left">{{ i.commerce_product_name }}</td>
                  <td class="px-2 py-1 text-right">Rp{{ (parseInt(i.commerce_product_price_discount) || parseInt(i.commerce_product_price) || parseInt(i.commerce_product_price_discount_temp) || parseInt(i.commerce_product_price_temp) || 0).toLocaleString() }}</td>
                  <td class="px-2 py-1 text-right">{{ i.commerce_product_quantity }}</td>
                  <td class="px-2 py-1 text-right">Rp{{ ((parseInt(i.commerce_product_price_discount) || parseInt(i.commerce_product_price) || parseInt(i.commerce_product_price_discount_temp) || parseInt(i.commerce_product_price_temp) || 0) * parseInt(i.commerce_product_quantity)).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td colspan="5">
                    <v-divider />
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="pa-2 text-right">
                    Subtotal : Rp{{ (parseInt(invoice.payment_subtotal) || 0).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <v-divider class="" />
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="pa-2 text-right">
                    Shipping Cost : Rp{{ (parseInt(invoice.payment_shipping) || 0).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <v-divider class="" />
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="pa-0 text-right font-weight-bold">
                    <div class="py-4 px-2 grey lighten-3 black--text text-subtitle-1 font-weight-bold">
                      Total : Rp{{ (parseInt(invoice.payment_total) || 0).toLocaleString() }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pa-4 text-body-2 text-left grey--text font-italic d-flex align-center fill-width">
            <v-icon class="mr-2" small color="grey">
              mdi-note-text
            </v-icon>
            Seller Notes : {{ invoice.notes || '-' }}
          </div>
          <div class="d-flex align-end fill-width justify-space-between">
            <div class="px-4 pt-4 text-body-2 text-left">
              <strong class="d-flex align-center pb-2 d-block grey--text text--darken-2">
                <v-icon small class="mr-2">mdi-credit-card</v-icon>
                Payment Options :
              </strong>
              <div v-for="(i, iP) in invoice.payment_options" :key="'payment-' + iP">
                <div class="d-flex align-center py-1 pl-1">
                  <span class="mr-2">{{ iP + 1 }}.</span>
                  <span class="ml-2 text-uppercase">{{ i.type }} {{ i.type_name }}, {{ i.bank_account_number }} a/n {{ i.bank_account_name }}</span>
                </div>
              </div>
            </div>
            <div v-if="parseInt(invoice.status) === 0">
              <v-btn depressed color="green" class="white--text font-weight-bold no-print" @click.prevent="DIALOG_CONFIRM_PAYMENT()">
                <v-icon class="mr-2">mdi-cash</v-icon>
                Confirm Payment
              </v-btn>
            </div>
            <div v-else class="text-right pa-4">
              Status: {{ StatusOrderName(invoice.status) }}
              <div>
                TTK NUMBER : {{ invoice.shipping_ttk || '?' }}
              </div>
            </div>
          </div>
        </div>
        <v-dialog
          v-model="dialogConfirm.show"
          persistent
          scrollable
          max-width="450"
        >
          <v-card v-if="dialogConfirm.data" class="px-4">
            <v-card-title class="body-1 justify-center text-uppercase font-weight-bold">
              {{ parseInt(dialogConfirm.data.image) ? 'DETAIL PAYMENT' : 'UPLOAD PAYMENT RECEIPT' }}
            </v-card-title>
            <v-divider />
            <v-card-text class="py-4 px-0" style="max-height: 90vh;overflow:auto;">
              <v-select
                v-model="dialogConfirm.data.payment_detail"
                :disabled="parseInt(invoice.status) ? true : false"
                :items="[{ name: '- Select Payment -' }].concat($StrToArray(invoice.commerce_client_payment_options).map(r => {
                  if (dialogConfirm.data.payment_detail && dialogConfirm.data.payment_detail.name === r.name) {
                    return dialogConfirm.data.payment_detail
                  }
                  return {
                    type: r.type,
                    name: r.name,
                    account_number: r.account_number,
                    account: r.account,
                    receipt: null,
                    notes: null
                  }
                }))"
                :item-text="(v) => {
                  return (
                    v.name !== '- Select Payment -'
                      ? ((v.name || '') + ' ' + (v.account_number || '?') + ' a/n ' + (v.account || '?')).toUpperCase()
                      : (v.name || '')
                  )
                }"
                return-object
                outlined
                hide-details
                dense
                class="d-block text-uppercase"
                label="Payment Options"
              />
              <div class="mb-0">
                <div v-if="dialogConfirm.data.payment_detail && dialogConfirm.data.payment_detail.receipt">
                  <img :src="dialogConfirm.data.payment_detail.receipt" class="d-block c-pointer fill-width" @click.prevent="!parseInt(invoice.status) ? UploadPrepare() : false">
                </div>
                <div v-else class="py-4">
                  <v-btn :disabled="dialogConfirm.data.payment_detail && dialogConfirm.data.payment_detail.name !== '- Select Payment -' ? (parseInt(invoice.status) ? true : false) : true" outlined height="75px" color="green" block large @click.prevent="UploadPrepare()">
                    Upload Payment Receipt
                  </v-btn>
                </div>
              </div>
              <v-text-field
                v-if="!parseInt(invoice.status)"
                v-model="dialogConfirm.data.passcode"
                type="text"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Passcode"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-0">
              <v-btn
                color="red darken-1"
                text
                @click="dialogConfirm.show = false"
              >
                Close
              </v-btn>
              <v-spacer />
              <v-btn
                :disabled="parseInt(invoice.status) ? true : false"
                color="green"
                dark
                depressed
                class="px-4 font-weight-bold"
                @click="CONFIRM_PAYMENT()"
              >
                Confirm Payment
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div v-else :class="[invoiceLoading ? 'text-body-2' : 'text-h4 red--text']" class="ma-auto grey lighten-4 text-center pa-8 d-flex align-center justify-center" style="max-width:1000px;min-height:100dvh;">
        {{ invoiceLoading ? 'Please wait ...' : 'Ops, 404!' }}
      </div>
    </v-main>
    <v-main v-else class="grey lighten-4">
      <div class="main-appbar white">
        <v-row no-gutters>
          <v-col cols="12" class="col-sm-12">
            <v-tabs
              v-if="$store.state.isClient"
              fixed-tabs
              :height="48"
              background-color="bg-business-primary"
              dark
            >
              <v-tab to="/commerce-order" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-cash-register
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Data Order
                </span>
              </v-tab>
              <v-tab to="/commerce-product" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-package-variant-closed
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Data Produk
                </span>
              </v-tab>
              <v-tab @click.prevent="DO_LOGOUT" class="text-capitalize ls-05 red--text white">
                <v-icon small color="red">
                  mdi-power
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Sign Out
                </span>
              </v-tab>
            </v-tabs>
            <v-tabs
              v-else
              fixed-tabs
              :height="48"
              background-color="bg-business-primary"
              dark
            >
              <v-tabs-slider :color="'secondary'" />
              <v-tab to="/" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-apps
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  {{ MyStoreName }}
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp', 'operation'], $store.state.user.role)" to="/shipping" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-truck-fast
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Shipping
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/commerce-order" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-cash-register
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Data Order
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/member" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-account-group
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Data Client
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/commerce-product" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-package-variant-closed
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Data Produk
                </span>
              </v-tab>
              <!-- <v-tab v-if="$role(['manager', 'svp', 'finance'], $store.state.user.role)" to="/finance" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-cash
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Finance
                </span>
              </v-tab> -->
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/store-manager" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-office-building-marker
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Cabang & Vendor
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/account-manager" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-account-cog-outline
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Account Manager
                </span>
              </v-tab>
              <!-- <v-tab v-if="$role([], $store.state.user.role)" to="/features/main-banner" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-newspaper-variant-outline
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Berita / Artikel
                </span>
              </v-tab> -->
              <v-tab @click.prevent="DO_LOGOUT" class="text-capitalize ls-05 red--text white">
                <v-icon small color="red">
                  mdi-power
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Sign Out
                </span>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </div>
      <div class="main-container">
        <v-container fluid>
          <router-view/>
        </v-container>
      </div>
    </v-main>
    <v-dialog
      v-model="loadingUpload"
      hide-overlay
      persistent
      width="300"
      content-class="rounded-lg2"
    >
      <v-card
        dark
        color="primary"
        class="rounded-lg2"
      >
        <v-card-text class="d-flex justify-center align-center flex-column pt-4 rounded-lg2 white--text">
          Uploading, please wait!
          <div class="px-4 fill-width">
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-2 rounded-lg2"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  head: (vm) => ({
    title: vm.$route.params.invoice ? (vm.invoice ? `Order #${vm.invoice.id.padStart(6, '0')}` : 'Invalid Invoice!') : 'Dashboard'
  }),
  data: () => ({
    invoice: null,
    invoiceLoading: false,
    dialogConfirm: {
      show: false,
      data: null
    },
    loadingUpload: false,
    StatusOrder: [
      { name: 'Tampilkan Semua', value: -1 },
      { name: 'New Order', value: 0 },
      { name: 'New Order (Paid)', value: 1000 },
      { name: 'Ready To Ship', value: 1 },
      { name: 'Shipping', value: 2 },
      { name: 'Completed', value: 3 },
      { name: 'Canceled', value: 99 }
    ]
  }),
  watch: {
    '$route.params.invoice': {
      async handler (v) {
        if (v) {
          await this.LOAD_INVOICE()
        }
      },
      immediate: true
    }
  },
  computed: {
    MyStoreName () {
      if (this.$store.state.user) {
        const store = this.$store.state.storeData.find(r => parseInt(r.id) === parseInt(this.$store.state.user.store))
        return store ? (store.code + ' - ' + store.name) : 'Dashboard'
      }
      return 'Dashboard'
    },
    ColorStoreActive () {
      // const storeActive = parseInt(this.$store.state.storeActive)
      const color = 'primary'
      // if (storeActive) {
      //   const store = this.$store.state.storeData.find(r => parseInt(r.id) === storeActive)
      //   if (store) {
      //     if (store.color) {
      //       return store.color
      //     }
      //   }
      //   const index = this.$store.state.storeData.findIndex(r => parseInt(r.id) === storeActive)
      //   switch (index) {
      //     case 0:
      //       color = 'primary'
      //       break
      //     case 1:
      //       color = 'secondary'
      //       break
      //     case 2:
      //       color = 'blue'
      //       break
      //     case 3:
      //       color = 'green'
      //       break
      //     case 4:
      //       color = 'teal'
      //       break
      //     case 5:
      //       color = 'lime darken-1'
      //       break
      //     case 6:
      //       color = 'orange'
      //       break
      //     case 7:
      //       color = 'indigo'
      //       break
      //     default:
      //       break
      //   }
      // }
      return color
    }
  },
  methods: {
    StatusOrderName (st) {
      return ((parseInt(st) >= 0 && parseInt(st) <= 99)
        ? ((this.StatusOrder.find(r => parseInt(r.value) === parseInt(st)) || {}).name || '-')
        : '-')
    },
    UploadPrepare () {
      const idEl = document.getElementById('file-uploader')
      if (idEl) {
        document.parentElement.removeChild(idEl)
      }
      const f = document.createElement('input')
      f.setAttribute('type', 'file')
      f.setAttribute('id', 'file-uploader')
      f.setAttribute('class', 'file-uploader')
      f.setAttribute('accept', '.jpg,.png,.jpeg')
      f.addEventListener('change', async (e) => {
        const files = e.target.files
        if (!files.length) {
          this.$store.dispatch('TOAST', { show: true, message: 'No file selected.' })
          return false
        }
        this.loadingUpload = true
        for (let i = 0; i < files.length; i++) {
          await this.GoUpload(files[i])
        }
        this.loadingUpload = false
      })
      f.click()
    },
    async GoUpload (f) {
      const file = Math.round((f.size / 1024))
      if (file >= 2000000000) {
        this.$store.dispatch('TOAST', { show: true, message: 'File terlalu besar!' })
        return false
      }
      const d = new FormData()
      d.append('file', f)
      this.loadingUpload = true
      await this.$store.dispatch('UPLOAD_FILE_BINARY', { data: d, query: '?source=order-receipt' }).then((resUpload) => {
        this.loadingUpload = false
        if (resUpload.status) {
          if (resUpload.data.data.res) {
            this.dialogConfirm.data.payment_detail.receipt = resUpload.data.data.secure_url
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, failed upload' })
          }
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Ops, please try again' })
        }
        this.loadingUpload = false
      })
      return true
    },
    CONFIRM_PAYMENT () {
      const payload = Object.assign({}, this.dialogConfirm.data)
      if (!payload.payment_detail || (payload.payment_detail && payload.payment_detail.name === '- Select Payment -')) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select payment options!' })
        return false
      }
      if (!payload.payment_detail || (payload.payment_detail && !payload.payment_detail.receipt)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please upload payment receipt!' })
        return false
      }
      if (!payload.passcode) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input passcode!' })
        return false
      }
      payload.payment_detail = this.$JsonToStr(payload.payment_detail)
      this.$store.dispatch('commerce/ORDER_INVOICE_CONFIRM', {
        invoice_hash: this.$route.params.invoice,
        data: payload
      })
        .then((res) => {
          if (res && res.status) {
            if (res.data.data.status) {
              this.$store.dispatch('TOAST', { show: true, message: 'Saved!' })
              this.dialogConfirm.show = false
              this.LOAD_INVOICE()
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          }
        })
    },
    DIALOG_CONFIRM_PAYMENT () {
      this.dialogConfirm.data = Object.assign({}, this.invoice)
      this.dialogConfirm.data.payment_detail = this.dialogConfirm.data.payment_detail ? this.$StrToJson(this.dialogConfirm.data.payment_detail) : null
      this.dialogConfirm.show = true
    },
    async LOAD_INVOICE () {
      this.invoiceLoading = true
      const invoice = await this.$store.dispatch('commerce/ORDER_INVOICE', this.$route.params.invoice)
      if (invoice && invoice.status) {
        if (invoice.data && invoice.data.data) {
          this.invoice = invoice.data.data
        } else {
          this.invoice = null
        }
        document.title = 'XTI : ' + (this.invoice ? `ORDER #${(this.invoice.id + '').padStart(6, '0')}` : 'Invalid Invoice!')
      }
      this.invoiceLoading = false
    },
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    }
  }
}
</script>

<style lang="scss">
html, a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
html {
  // height: 100vh!important;
  // overflow: hidden!important;
  >body {
    // overflow: hidden!important;
    >.v-application {
      // height: 100vh!important;
      // overflow: auto!important;
      .display-4,
      .display-3,
      .display-2,
      .display-1,
      .headline,
      .title,
      .subtitle-1,
      .subtitle-2,
      .body-1,
      .body-2,
      .caption,
      .overline {
        font-family: 'Poppins', sans-serif!important;
      }
    }
  }
}

.main-appbar {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
  height: 48px!important;
}
.main-container {
  padding-top: 48px;
  position: relative;
  // overflow-y: auto;
}
.c-pointer {
  cursor: pointer!important;
}
.fill-width {
  width: 100%!important;
}
.p-relative {
  position: relative!important;
}
.ls-0 {
  letter-spacing: 0px!important;
}
.ls-05 {
  letter-spacing: .5px!important;
}
.ls-1 {
  letter-spacing: 1px!important;
}
.lh-0 {
  line-height: 100%!important;
}
a {
  text-decoration: none!important;
  color: unset!important;
}
.v-card {
  border-radius: 0px!important;
}
.file-uploader {
  width: 0px;
  height: 0px;
  position: fixed;
  bottom: -100%;
  z-index: -2;
}
.v-tabs {
  .v-slide-group__prev,
  .v-slide-next {
    display: none!important;
  }
}
.theme--dark {
  &.btn-checkout {
    &.v-btn--disabled {
      &.v-btn--has-bg {
        &.v-btn {
          background-color: rgba(0, 0, 0, .1)!important;
          color: rgba(0, 0, 0, .3)!important;
        }
      }
    }
  }
}
.caption {
  letter-spacing: 0px!important;
}
.caption-small {
  font-size: 12px!important;
  letter-spacing: 0px;
}
.v-textarea {
  textarea {
    line-height: 125%!important;
  }
}
a {
  &.disabled {
    pointer-events: none!important;
  }
}

.v-calendar-monthly {
  border-radius: 4px!important;
  // overflow: hidden;
  &.v-calendar-weekly {
    @media only screen and (max-width: 768px) {
      border-radius: 0px!important;
      width: 117%;
      margin-left: -8.5%!important;
      margin-right: -8.5%!important;
      border: solid 0px!important;
    }
  }
  .v-calendar-weekly__head {
    .v-calendar-weekly__head-weekday {
      background-color: #262d44!important;
      padding: 4px 4px 4px 4px;
      font-weight: bold;
      color: #ffffff!important;
      &.v-outside {
        color: #9e959f!important;
      }
      &.v-present {
        color: #fcb068!important;
      }
    }
  }
  .v-calendar-weekly__day {
    min-height: 45px;
    padding: 0px 5px 10px 5px;
    .v-event {
      margin-top: 5px!important;
      width: 100%!important;
      height: 12px!important;
      display: flex!important;
      justify-content: flex-start!important;
      align-items: center!important;
      line-height: unset!important;
      // left: 55%;
      left: 0px;
      border-radius: 20px!important;
      >.booking-counter {
        position: relative;
        padding: 0px!important;
        font-size: .5rem!important;
        width: 100%;
        height: 100%;
        text-align: center;
        display:flex;
        justify-content: center!important;
        align-items: center!important;
        span {
          &.booking-counter-progress {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            background-color: #262d44;
            z-index: -1;
          }
        }
      }
    }
    :nth-child(2) {
      &.v-event {
        // margin-top: -10px!important;
        margin-top: 5px!important;
        margin-bottom: 5px!important;
        &.grey {
          background: transparent!important;
        }
        >.booking-counter {
          span {
            color: #262d44;
            font-size: .7rem;
            font-weight: bold;
            &.booking-counter-progress {
              display: none;
            }
          }
        }
      }
    }
    &.v-present {
      background: #fbede6;
    }
    >.v-calendar-weekly__day-label {
      margin-top: 10px;
      margin-bottom: 0px;
      .v-btn--fab {
        &.v-size--small {
          width: 25px!important;
          height: 25px!important;
        }
        &.primary {
          background-color: #f4700b!important;
          color: #fff!important;
        }
        &.transparent {
          background-color: #e3d9e9!important;
          color: #412a54!important;
        }
      }
    }
    .v-event-more {
      display: none;
    }
  }
}
table {
  thead {
    &.v-data-table-header {
      >tr {
        // background-color: #262d44!important;

        background: linear-gradient(145deg, #024434 30%, #cf7600 100%)!important;
        >th {
          // height: 80!important;
          border-bottom: 0px!important;
          >i.theme--light,
          >span {
            color: #fff!important;
          }
          >i.theme--light {
            font-size: .8rem!important;
            margin-left: 5px!important;
            margin-top: -1px!important;
          }
          >.v-data-table__checkbox  {
            >.v-input--selection-controls__input {
              >i {
                &.theme--light {
                  &.v-icon {
                    color: #ffffff!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.caption-small {
  font-size: .7rem!important;
}

@media screen {
  // .dialog-printed {
  //   display: none;
  // }
}
.print-only {
  display: none;
}
#dialogPrintPDF {
  &.row {
    margin: 0px!important;
    .col {
      padding: 2px!important;
      padding-bottom: 4px!important;
      &.col-left {
        padding-right: 2px!important;
        border-right: dashed 1px #000!important;
        border-bottom: dashed 1px #000!important;
      }
      &.col-right {
        padding-left: 8px!important;
        border-bottom: dashed 1px #000!important;
      }
    }
  }
}
@mixin print-area {
  .no-print {
    display: none!important;
    visibility:hidden!important;
  }
  body {
    font-size: 10px!important;
  }
  .print-only {
    display: block;
  }
  .v-application {
    width:100%!important;
    height:auto!important;
    &:not(.print-force) {
      >.v-application--wrap {
        display: none!important;
      }
    }
  }
  .v-dialog__content {
    height: auto!important;
    max-height: unset!important;
    width: 100%!important;
    position: relative!important;
    align-items: flex-start!important;
    justify-content: start!important;
    .v-dialog {
      max-height: unset!important;
      &.dialog-printed {
        visibility:hidden!important;
        border-radius: 0px!important;
        margin: 0px!important;
        width:100%!important;
        .print-content {
          >.container {
            min-width: unset!important;
          }
        }
        >.v-card,
        >.v-card * {
          visibility: visible;
          overflow: visible !important;
        }
        >.v-card {
          box-shadow: none!important;
          position:absolute;
          left:0;
          top:0;
        }
        .abc-logo {
          height: 45px!important;
        }
        canvas {
          height: 120px!important;
          width: 120px!important;
        }
        .text-h5 {
          font-size: 12px!important;
        }
        .v-divider {
          display: none;
        }
        .primary {
          &.white--text {
            &.pa-2 {
              padding: 0px!important;
              color: #000!important;
            }
          }
        }
        .my-2 {
          margin-top: 0px!important;
          margin-bottom: 0px!important;
        }
        .row {
          margin: 0px!important;
          .col {
            padding: 2px!important;
            padding-bottom: 4px!important;
            &.col-left {
              padding-right: 2px!important;
              border-right: dashed 1px #000!important;
              border-bottom: dashed 1px #000!important;
            }
            &.col-right {
              padding-left: 8px!important;
              border-bottom: dashed 1px #000!important;
            }
          }
        }
        .pa-4 {
          padding: 4px!important;
        }
      }
    }
  }
}

html {
  &.print-area {
    @include print-area;
  }
}
@media print {
  html {
    &.overflow-y-hidden {
      overflow: auto!important;
    }
  }
  @include print-area;
}
// table {
//   &.table-print {
//     border-spacing: 0px;
//     border-collapse: collapse;  /* <--- add this so all the internal <td>s share adjacent borders  */
//     border: 1px solid black!important;
//     tr {
//       border: 1px solid rgba(0, 0, 0, .5)!important;
//       border-collapse: collapse;
//       border-spacing: 0px;
//       td {
//         border: 1px solid rgba(0, 0, 0, .5)!important;
//       }
//     }
//   }
// }
@media (min-width: 1264px) {
  .container {
    max-width: 100%!important;
  }
}

.bg-business-primary {
  background: #262d44;
  background: linear-gradient(145deg, #024434 30%, #cf7600 100%)!important;
}
.bg-business-secondary {
  background: #262d44;
  background: linear-gradient(145deg, #024434 30%, #cf7600 100%)!important;
}
.business-tabs-items {
  >.v-window__container {
    height: 100%!important;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 40px!important;
}

.main-container {
  &.dashboard {
    padding: 0px!important;
    height: 100%!important;
    background-color: #f0f0f0;
    >.container {
      max-width: 1000px!important;
      height: 100%!important;
      padding: 0px!important;
      >.v-card {
        height: 100%!important;
        .v-toolbar__title {
          .mdi-chevron-down {
            display: none;
          }
        }
        .v-toolbar__items {
          >button {
            display: none;
            &.dashboard-logout {
              display: block!important;
            }
          }
        }
      }
      .confirm-delivery-and-update {
        display: none!important;
      }
    }
  }
}
.bg-glass {
  /* From https://css.glass */
  background: rgba(254, 254, 254, 0.46);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(254, 254, 254, 0.66);
}
html,
body {
  scroll-behavior: smooth;
}
</style>
