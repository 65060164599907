<template v-if="$store.state.user">
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height primary" dark outlined @click.prevent="$store.state.isClient ? DIALOG_SHOW() : $store.dispatch('TOAST', { show: true, message: 'Customer access only!' })">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Add Product
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-5">
        <div class="pl-4 pl-sm-8 pr-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class=""
            placeholder="Search product ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-3">
        <div class="px-4 pt-4 pb-4">
          <v-select
            v-model.number="tableSearchStatus"
            :items="[
              { name: 'Tampilkan Semua', value: -1 },
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class=""
            label="Product Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              <div class="d-flex align-center text-uppercase">
                <v-icon class="mr-1">
                  mdi-barcode
                </v-icon>
                <span class="font-weight-bold caption">
                  #{{ ('000000' + item.id).slice(-6) }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex flex-column py-2">
              <div class="d-flex fill-width align-center justify-space-between text-capitalize">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.commerce_client_name`]="{ item }">
            <div class="d-flex flex-column py-2">
              <div class="d-flex fill-width align-center justify-space-between text-capitalize">
                <span class="font-weight-bold">
                  {{ item.commerce_client_name || '-' }}
                </span>
              </div>
            </div>
          </template>
          <!-- <template v-slot:[`item.price`]="{ item }">
            <div class="text-right">
              Rp{{ parseInt(item.price).toLocaleString() }}
            </div>
          </template> -->
          <template v-slot:[`item.price_discount`]="{ item }">
            <div class="text-right">
              <span v-if="parseInt(item.price_discount)" class="d-flex align-center justify-end">
                <span class="mr-2 text-decoration-line-through red--text">
                  Rp{{ parseInt(item.price).toLocaleString() }}
                </span>
                <span>
                  Rp{{ parseInt(item.price_discount).toLocaleString() }}
                </span>
              </span>
              <span v-else>
                Rp{{ parseInt(item.price).toLocaleString() }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) ? 'blue darken-2' : 'orange darken-2'" outlined dark small>
                {{ parseInt(item.status) ? 'Active ' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn depressed small dark color="orange" class="text-capitalize" @click.prevent="DIALOG_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <div class="pt-2 pb-2 px-4 px-md-8 white fill-width">
              <v-divider class="mt-2 mb-3" />
              <div class="d-flex justify-end flex-column flex-sm-row">
                <div class="d-flex align-center font-weight-bold">
                  Total : {{ tableTotal }}
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialog.data" >
        <v-card-title class="body-1 justify-center">
          - {{ dialog.data.id ? 'Update Produk' : 'Tambah Produk Baru' }} -
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: calc(100vh - 200px)">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="dialog.data.name"
                outlined
                rows="1"
                dense
                hide-details
                label="Product Name"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="dialog.data.description"
                outlined
                rows="2"
                dense
                hide-details
                label="Product Description"
              />
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="col-sm-6 d-flex align-center">
                  <v-text-field
                    v-model.number="dialog.data.price"
                    outlined
                    dense
                    hide-details
                    prefix="Rp"
                    label="Default Price"
                  />
                </v-col>
                <v-col cols="12" class="col-sm-6 d-flex align-center">
                  <v-text-field
                    v-model.number="dialog.data.price_discount"
                    outlined
                    dense
                    hide-details
                    prefix="Rp"
                    label="Price Discount"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="col-sm-6 d-flex align-start justify-center flex-column">
                  <div class="d-flex align-center pb-1">
                    Volume : {{ parseFloat(dialogCalculate.weight_volume).toLocaleString() }} gram(s)
                  </div>
                  <div class="d-flex align-center">
                    <div class="d-flex align-center px-2 py-1 grey lighten-3 mr-1 rounded-md">
                      P
                      <input v-model.number="dialog.data.weight_length" class="ml-2 mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 40px;">
                      cm
                    </div>
                    <div class="d-flex align-center px-2 py-1 grey lighten-3 mr-1 rounded-md">
                      L
                      <input v-model.number="dialog.data.weight_width" class="ml-2 mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 40px;">
                      cm
                    </div>
                    <div class="d-flex align-center px-2 py-1 grey lighten-3 mr-1 rounded-md">
                      T
                      <input v-model.number="dialog.data.weight_height" class="ml-2 mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 40px;">
                      cm
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="col-sm-3 d-flex align-start justify-center flex-column">
                  <div class="d-flex align-center fill-width pb-1">
                    Weight
                  </div>
                  <div class="d-flex align-start fill-width flex-column">
                    <div class="d-flex align-start">
                      <input v-model.number="dialog.data.weight_kg" class="mr-1 d-inline-block grey lighten-2 rounded-md text-center" style="width: 60px;"> gram(s)
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="col-sm-3 d-flex align-start justify-center flex-column">
                  <div class="d-flex align-center fill-width pb-1">
                    Chargeable Weight
                  </div>
                  <div class="d-flex align-start fill-width flex-column">
                    <div class="d-flex align-start font-weight-bold">
                      {{ parseFloat(dialogCalculate.chargeable).toLocaleString() }} gram(s)
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            text
            class="text-capitalize"
            @click="dialog.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <!-- :disabled="!$store.state.isClient" -->
          <v-btn
            color="green darken-1"
            depressed
            dark
            class="text-capitalize px-4 font-weight-bold"
            @click="PRODUCT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
let searchInterval = null
export default {
  name: 'Customer',
  data: () => ({
    areaqueryFrom: '',
    areasFrom: [],
    areaqueryTo: '',
    areasTo: [],
    menu: false,
    dates: [],
    headers: [
      {
        text: 'PRODUCT ID',
        value: 'id'
      },
      { text: 'Client', value: 'commerce_client_name' },
      { text: 'Name', value: 'name' },
      { text: 'Price', value: 'price_discount' },
      { text: 'Status', value: 'status' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: -1,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 25,
      page: 1
    },
    dialog: {
      show: false,
      data: null
    },
    loading: false
  }),
  watch: {
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.PRODUCT_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.PRODUCT_GET(this.tableSearch)
    },
    options () {
      this.PRODUCT_GET(this.tableSearch)
    }
  },
  computed: {
    dialogCalculate () {
      if (this.dialog.show && this.dialog.data) {
        const d = Object.assign({}, this.dialog.data || {})
        const wkg = parseFloat(d.weight_kg) || 0
        const vlength = parseFloat(d.weight_length) || 0
        const vwidth = parseFloat(d.weight_width) || 0
        const vheight = parseFloat(d.weight_height) || 0
        const tVolume = vlength * vwidth * vheight
        const tVolumeWeight = tVolume > 0 ? (tVolume / 5000) : 0
        return {
          weight_kg: wkg,
          weight_volume: tVolumeWeight * 1000,
          chargeable: Math.max(wkg, tVolumeWeight * 1000)
        }
      }
      return {
        weight_kg: 0,
        weight_volume: 0,
        chargeable: 0
      }
    }
  },
  created () {
    // if (this.$store.state.user) {
    //   if (!this.$role(['manager', 'svp'], this.$store.state.user.role)) {
    //     this.$router.replace('/')
    //   }
    // } else {
    //   this.$router.replace('/')
    // }
  },
  methods: {
    DIALOG_SHOW (data) {
      if (data) {
        this.dialog.data = Object.assign({}, data)
      } else {
        this.dialog.data = {
          commerce_client: 0, // product owner
          name: null,
          description: null,
          images: null,
          price: 0,
          price_discount: 0,
          weight_kg: 0,
          weight_length: 0,
          weight_width: 0,
          weight_height: 0,
          status: 1
        }
      }
      this.dialog.show = true
    },
    PRODUCT_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please wait!' })
        return false
      }
      const processedData = Object.assign({}, this.dialog.data)
      if (!processedData.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama produk masih kosong!' })
        return false
      }

      if (!processedData.description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Keterangan produk masih kosong!' })
        return false
      }

      this.loading = true
      this.$store.dispatch('commerce/PRODUCT_CREATE', processedData)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (res.data.data.status) {
              this.PRODUCT_GET(this.tableSearch)
              this.dialog.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedData.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    PRODUCT_GET (q) {
      const status = this.tableSearchStatus > -1 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('commerce/PRODUCT_SEARCH', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : ''))
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>
